<template>
    <label v-if="label">{{label}}</label>

    <DataLookupDropdown  :dataLookupControl="lookupControl" :modelValue = "modelValue" :value="value"  v-bind="$attrs">
        <template v-if="useDialog" #header>
            <slot name="toolbarActions"></slot>
            <slot name="toolbarDesktopActions"></slot>
        </template>
        <template #target="scope">    
            <!--@slot target
                @description Target button to open lookup can be customized
                @example 
                <template #target="{target}">
    <div :ref="target">{{dsMaster.current.MasterField}}</div>
</template>
                -->
            <slot name="target" :target="scope.target" :open="scope.open"></slot>  
        </template>
        <template #listContainer>
            <DataLookupList :dataLookupControl="lookupControl">
            
                <template #actions>
                <!--@slot actions
                    @description Actions slot can be used to add aditional actions. Added to bottom.
                    @example 
                    <template #actions>
    <button>My Action</button>
</template> 
-->
                <slot name="actions"></slot>
                <!--@slot desktopActions
                    @description desktopActions slot can be used to add aditional actions on desktop only. Added to bottom.
                    @example 
                    <template #desktopActions>
    <button>My Action</button>
</template> 
-->
                <slot name="desktopActions"></slot>
                </template>

                <template #header v-if="$slots.header">
                    <!--@slot header
                        @description Header slot that can be used to add aditional content above the toolbar.
                    -->
                    <slot name="header"></slot>
                </template>
            
                <template #toolbar>
                
                <span style="cursor:pointer;float:right " v-if="!useDialog  && (slots['toolbarActions'] || slots['toolbarDesktopActions'])">
                    <!--@slot toolbarActions
                        @description ToolbarActions slot can be used to add aditional actions. Added to top.
                    -->
                    <slot name="toolbarActions"></slot>
                    <!--@slot toolbarDesktopActions
                        @description ToolbarDesktopActions slot can be used to add aditional actions only on desktop. Added to top.
                    -->
                    <slot name="toolbarDesktopActions"></slot>
                </span>

                </template>
                <!--@slot columns 
                    @type columns-->
                <slot></slot>
            </DataLookupList>
        </template>
    </DataLookupDropdown>
</template>

<script setup>
/**
* Data lookup component
* @class form-select
* @placeholder Choose
* @definition
*/
import DataLookupDropdown from './desktop.DataLookupDropdown.vue';
// import DataLookupDropdown1 from './desktop.DataLookupDropdown.vue';
// import DataLookupDropdown2 from './desktop.DataLookupDialog.vue';
// import { userSession } from 'o365-modules';
import DataLookupList from './desktop.DataLookupList.vue';
import DataLookupControl from './DataLookup.ts';
import DesktopProps from './props.desktop.ts';
import { InjectionKeys } from 'o365-utils';

import { ref, watch, useSlots, reactive, onMounted, inject, defineAsyncComponent } from 'vue';

// const useDialog = userSession.personId == 96470;
const useDialog = false;

// const DataLookupDropdown = useDialog
    // ? DataLookupDropdown2
    // : DataLookupDropdown1;

const slots = useSlots();

const props = defineProps(DesktopProps);

const lookupControl = reactive(new DataLookupControl(props));

const gridEditorApi = inject(InjectionKeys.dataGridEditorCellControlKey, null);

watch(() => props.whereClause, (newValue) => {
    lookupControl.whereClause = newValue;
});

watch(() => props.bind, (newValue) => {
    lookupControl._bind = newValue;
});

function activateEditor() {
    lookupControl.dropdown?.target?.focus();
    lookupControl.dropdown?.open();
}

function closeEditor() {
    lookupControl.dropdown?.target?.focus();
    lookupControl.dropdown?.close();
}

onMounted(() => {
    if (props.dataObject && props.multiselect) {
        props.dataObject.selectionControl.enableKeepSelectionAfterLoad();
    }
    if (gridEditorApi && gridEditorApi.activate) {
        gridEditorApi.activate();
    }
})

defineExpose({ lookupControl, activateEditor,closeEditor });
</script>

<style>
    .o365-data-lookup{
        z-index:1021!important;
        outline: none;
    }
     .o365-header-row [o365-field='o365_pinned']{
        display:none;
    }

    .o365-lookup-active {
        background-color: rgba(red, green, blue, alpha);
        background-color: rgba(var(--bs-primary-rgb), .20)!important;
    }
    .o365-lookup-active.selectable {
        background-color: rgba(0, 24, 255, 0.25)!important;
    }
</style>